/* ICONOS HEADER HOME */

/* styles.css */
.card-item {
  flex: 1 1 200px;
  margin: 1rem 0.5rem;
  max-width: 300px;
  background: white;
  border: 1px solid #e4e4e4;
  border-radius: 15px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 100px;
}

.card-item img {
  width: 32px;
  margin: 0 auto;
}

.card-title {
  display: flex;
  color: grey;
  font-weight: bold;
  justify-content: space-evenly;
  align-items: center;
}
.card-icon {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.card-icon img {
  width: 32px;
  margin: 0 auto;
}
.card-content {
  font-weight: bold;
  font-size: larger;
}

/* FIN ICONOS HEADER HOME */

/* PROXIMOS EVENTOS */
.container-events {
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 16px;
}

.header {
  font-weight: bold;
  margin-bottom: 16px;
  text-align: left;
  color: #333;
}

.event {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  padding: 8px 0;
}

.event:last-child {
  border-bottom: none;
}

.event-date,
.event-name,
.event-participants {
  display: flex;
  flex-direction: column;
  justify-self: center;
  min-width: 60px;
}

.event-date {
  color: var(--color-title-grey);
  font-weight: bold;
  border-left: 2px solid var(--secondary-color);
  text-align: start;
  max-width: 100px;
  padding-left: 5px;
  font-size: 14px;
}

.event-name {
  flex-grow: 1;
  text-align: left;
  padding-left: 8px;
  color: #333;
  font-size: 14px;
}

.event-participants {
  color: #888;
  font-size: 14px;
}

.view-more {
  text-align: center;
  margin-top: 16px;
  color: #f57c00;
  cursor: pointer;
}

.view-more:hover {
  text-decoration: underline;
}
/* FIN DE PROXIMOS EVENTOS */
