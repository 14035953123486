.imageContainer {
  flex-grow: 1;
  padding: 20px;
  text-align: center;
  height: 100%;
}

.dropZone {
  border: 1px solid var(--field-grey);
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.uploadedImage {
  max-width: 100%;
  max-height: 100%;
}
.cloudIcon {
  padding-left: 10px;
  margin-bottom: 10px;
}

.dropZoneError {
  border: 2px dashed #d32f2f;
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
