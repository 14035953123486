.squaredBorder {
  position: relative;
  border-radius: 0 !important;
  &::after {
    content: "";
    width: 90%;
    height: 90%;
    border: 2px solid #fff;
    position: absolute;
    top: 5%;
    left: 5%;
  }
}
