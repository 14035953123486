.format-card {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  cursor: pointer;
  .text {
    width: 65%;
  }

  .box-1 {
    width: 50px;
    height: 50px;
    background-color: lightgray;
    border-radius: 10px;
  }
  .box-2 {
    width: 100px;
    height: 50px;
    background-color: lightgray;
    border-radius: 10px;
  }
  .box-3 {
    width: 70px;
    height: 50px;
    background-color: lightgray;
    border-radius: 10px;
  }
  .box-4 {
    width: 40px;
    height: 50px;
    background-color: lightgray;
    border-radius: 10px;
  }
  .box-5 {
    width: 40px;
    height: 50px;
    background-color: lightgray;
    position: relative;
    &::after {
      content: "";
      width: 90%;
      height: 90%;
      border: 2px solid #fff;
      position: absolute;
      top: 5%;
      left: 5%;
    }
  }
  .box-6 {
    width: 90px;
    height: 50px;
    background-color: lightgray;
    border-radius: 10px;
  }
}
