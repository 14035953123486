/* Header */
.basic {
  height: 50px;
  cursor: pointer;
}

.middleCircle {
  position: relative;
  height: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    width: 60px;
    height: 50px;
    border-radius: 50%;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.rounded {
  height: 50px;
  border-radius: 0% 0% 20% 20% / 10% 10% 50% 50%;
  cursor: pointer;
}

/* Footer */
.middle-circle-footer {
  position: relative;
  background-color: #d9d9d9;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    width: 60px;
    height: 50px;
    background-color: #d9d9d9;
    border-radius: 50%;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.rounded-footer {
  background-color: #d9d9d9;
  height: 50px;
  border-radius: 20% 20% 0% 0%/ 50% 50% 10% 10%;
  cursor: pointer;
}

.color-input {
  width: 60px;
}

.cardLeft {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 75%;
}

.cardRight {
  width: 25%;
  border-left: 0.18em dashed #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 0.9em;
    height: 0.9em;
    background: #fff;
    border-radius: 50%;
    left: -0.5em;
  }
  &:before {
    top: -0.4em;
  }
  &:after {
    bottom: -0.4em;
  }
}

.card {
  height: 11em;
  float: left;
  position: relative;
  padding: 1em;
}

.eye {
  position: relative;
  width: 2em;
  height: 1.5em;
  background: #fff;
  margin: 0 auto;
  border-radius: 1em/0.6em;
  z-index: 1;
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
  }
  &:before {
    width: 1em;
    height: 1em;
    z-index: 2;
    left: 8px;
    top: 4px;
  }
  &:after {
    width: 0.5em;
    height: 0.5em;
    background: #fff;
    z-index: 3;
    left: 12px;
    top: 8px;
  }
}
